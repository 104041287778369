import { useDispatch, useSelector } from "react-redux";
import classes from "./DocumentsPage.module.scss";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { IDocumentRow } from "../../interfaces";
import { DownloadIcon } from "../../../assets/icons/DownloadIcon";
import {
  documentsErrorSelector,
  documentsLoadingSelector,
  documentsSelector
} from "../../../redux/selectors/documentsSelector";
import { useEffect } from "react";
import {
  DOWNLOAD_DOCUMENT_REQUEST,
  GET_DOCUMENTS_REQUEST
} from "../../../redux/actions";
import { Loader } from "../../common/Loader/Loader";
import { format } from "date-fns";

export const DocumentsPage = () => {
  const dispatch = useDispatch();
  const documents: IDocumentRow[] = useSelector(documentsSelector);
  const isLoading = useSelector(documentsLoadingSelector);
  const isError = useSelector(documentsErrorSelector);

  useEffect(() => {
    dispatch({
      type: GET_DOCUMENTS_REQUEST
    });
  }, [dispatch]);

  const downloadFile = (file_source: string) => {
    dispatch({
      type: DOWNLOAD_DOCUMENT_REQUEST,
      payload: { file_source }
    });
  };

  return (
    <FullPageContainer className={classes.documentsWrapper}>
      <div>
      <div className={classes.documentsTableContainer}>
            <h4>Links</h4>
            <Table aria-label="Links table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Upload Date</TableCell>
                  <TableCell align="left">Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>         
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">                 
                      <span className={classes.fileTypeBadge}>
                        {"URL"} 
                      </span>
                      <a href="#" rel="noopener noreferrer">Owner Portal</a>
                  </TableCell>
                  <TableCell align="left">{"26.04.2023 9:04 AM"}</TableCell>
                  <TableCell align="left">
                          <p className={classes.downloadAction}>
                            <DownloadIcon />
                            Open
                          </p>
                        </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>



        <div className={classes.documentsTableContainer}>
          <h4>Reports &amp; Documents</h4>
          {!documents && (
            <div className={classes.loaderContainer}>
              <Loader isLoading={isLoading} isError={isError} data={documents} />
            </div>
          )}
          {documents && (
            <Table aria-label="Links, Reports and Documents table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Upload Date</TableCell>
                  <TableCell align="left">Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {documents.map(
                  ({
                    file_id,
                    file_name,
                    file_source,
                    file_type,
                    upload_date
                  }: IDocumentRow) => {
                    const uploadDate = format(
                      new Date(upload_date),
                      "dd.MM.yyyy h:mm a"
                    );
                    return (
                      <TableRow className={classes.tableRow} key={file_id}>
                        <TableCell align="left">
                          <p>
                            <span className={classes.fileTypeBadge}>
                              {file_type}
                            </span>
                            <span className={classes.fileName}>
                              {file_name}
                              {"."}
                            </span>
                            <span className={classes.fileType}>{file_type}</span>
                          </p>
                        </TableCell>
                        <TableCell align="left">{uploadDate}</TableCell>
                        <TableCell align="left">
                          <a
                            className={classes.downloadAction}
                            download={file_name}
                            href={file_source}
                            rel="noreferrer"
                            target={file_type === "pdf" ? "_blank" : "_self"}
                          >
                            <DownloadIcon />
                            {/* <Button
                              
                              // onClick={() => downloadFile(file_source)}
                              startIcon={}
                            > */}
                            Open
                            {/* </Button> */}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </FullPageContainer>
  );
};
