import { call, put, takeLatest } from "redux-saga/effects";
import { UserLoginAPI } from "../../../api/loginUserAPI";
import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from "../../actions";

function* workerForgotPassword(action: {
  type: string;
  //payload: { property_id: string };
  payload: { property_id: string; password: string; password_confirmation:string; navigate: Function };

}): Generator<any> {
  try {
    const { property_id, password,password_confirmation, navigate } = action.payload;

    const data: any = yield call(UserLoginAPI.forgotPassword, {
      property_id,
      password,
      password_confirmation
    });
    yield put({
      type: FORGOT_PASSWORD_SUCCESS
    });
    
    yield navigate("/login");

  } catch (error: any) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword);
}
