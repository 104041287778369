import {
  createErrorSelector,
  createLoadingSelector
} from "../../utils/reduxUtils";
import { GET_DOCUMENTS } from "../actions";

export const documentsSelector = (state: any) => state.documents.documents;

export const documentsLoadingSelector = createLoadingSelector([GET_DOCUMENTS]);
export const documentsErrorSelector = createErrorSelector([GET_DOCUMENTS]);
