import { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput
} from "@mui/material";
import classes from "./ForgotPasswordForm.module.scss";
import { Visibility } from "../../../../../assets/icons/Visibility";
import { CheckboxIcon } from "../../../../../assets/icons/CheckboxIcon";
import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_USER_REQUEST
} from "../../../../../redux/actions";
// import { ForgotPasswordModal } from "../ForgotPasswordModal/ForgotPasswordModal";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

export const ForgotPasswordForm = (props: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);  
  const [submitted, setSubmitted] = useState(false);
  const isMobileView = useSelector(isMobileModeSelector);  
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowPassword2 = () => setShowPassword2(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  }; 

  const handleForgotPassword = () => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: {
        property_id: email,
        password, 
        password_confirmation,
        navigate
      }
    });
    setSubmitted(true);
  };  
  
  return (
    <div className={classes.loginFormContainer}>
      <h2>Reset password!</h2>
      <p>Enter your credentials to reset the password</p>
      <FormControl fullWidth>
        <OutlinedInput
          id="email"
          className={classes.input}
          fullWidth
          name="email"
          onChange={event => setEmail(event.target.value)}
          placeholder="Enter your Property_ID"
          type="email"
          value={email}
        />
      </FormControl>
   
      <FormControl fullWidth>      
        <OutlinedInput
          id="password"
          className={classes.input}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          name="password"
          onChange={event => setPassword(event.target.value)}
          placeholder="Enter your new password"
          type={showPassword ? "text" : "password"}
          value={password}
        />        
        </FormControl>

        <FormControl fullWidth>         
        <OutlinedInput
          id="password_confirmation"
          className={classes.input}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword2}
                onMouseDown={handleMouseDownPassword2}
              >
                {showPassword2 ? <Visibility /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          name="password_confirmation"
          onChange={event => setPasswordConfirmation(event.target.value)}
          placeholder="Enter confirm password"
          type={showPassword2 ? "text" : "password"}
          value={password_confirmation}
        />
        <div className={classes.formGroup}>
        </div>
        <Button
          sx={{ borderRadius: isMobileView ? '24px' : '16px', height: isMobileView ? '62px' : '56px' }}
          className={classes.action}
          color="primary"
          onClick={handleForgotPassword}
          variant="contained"
        >
          {submitted && props.isLoading ? (
            <CircularProgress classes={{ root: classes.loader }} />
          ) : (
            "Reset"
          )}
        </Button>
      </FormControl>      
    </div>
  );
};
export default ForgotPasswordForm;
