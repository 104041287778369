export const DocumentIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.52632 24C1.83158 24 1.23705 23.7652 0.742737 23.2956C0.247579 22.8252 0 22.26 0 21.6V2.4C0 1.74 0.247579 1.1748 0.742737 0.7044C1.23705 0.2348 1.83158 0 2.52632 0H12.6316L20.2105 7.2V10.92L10.1053 20.49V24H2.52632ZM12.6316 24V21.45L19.1368 15.24L21.8526 17.76L15.3158 24H12.6316ZM22.7684 16.92L20.0842 14.37L20.9684 13.53C21.2211 13.29 21.5263 13.17 21.8842 13.17C22.2421 13.17 22.5368 13.29 22.7684 13.53L23.6526 14.4C23.8842 14.64 24 14.9248 24 15.2544C24 15.5848 23.8842 15.86 23.6526 16.08L22.7684 16.92ZM11.3684 8.4H17.6842L11.3684 2.4V8.4Z"
      fill="#B4B7CF"
    />
  </svg>
);
