import { all, call, put, takeLatest } from "redux-saga/effects";
import { RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_RESERVATIONS_BY_CHANNEL_ERROR,
  GET_RESERVATIONS_BY_CHANNEL_REQUEST,
  GET_RESERVATIONS_BY_CHANNEL_SUCCESS
} from "../../actions";

function* workerReservationsByChannel(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      call(RevenueAPI.getBookingReservations, params),
      call(RevenueAPI.getExpediaReservations, params),
      call(RevenueAPI.getVrboReservations, params),
      call(RevenueAPI.getAirbnbReservations, params),
      call(RevenueAPI.getReservationsSource, params)
    ]);
    const [booking, expedia, vrbo, airbnb, source] = data;
    yield put({
      type: GET_RESERVATIONS_BY_CHANNEL_SUCCESS,
      payload: {
        reservations: {
          [params.param]: {
            booking,
            expedia,
            vrbo,
            airbnb,
            source
          }
        }
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_RESERVATIONS_BY_CHANNEL_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherReservationsByChannel() {
  yield takeLatest(
    GET_RESERVATIONS_BY_CHANNEL_REQUEST,
    workerReservationsByChannel
  );
}
