import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isMobileModeSelector } from '../../../redux/selectors/appStatusSelector';
import { forgetPasswordLoadingSelector, forgetPasswordErrorSelector } from '../../../redux/selectors/forgetPasswordSelector';
import { getAuthToken } from '../../../utils/apiUtils';
import { Logo } from '../../common/Logo/Logo';
import { Toast } from '../../common/Toast/Toast';
import { ForgotPasswordForm } from './components/ForgotPasswordForm/ForgotPasswordForm';
import classes from './ForgotPasswordPage.module.scss';

export const ForgotPasswordPage = () => {
  const token = getAuthToken();
  const isMobileView = useSelector(isMobileModeSelector);
  const isLoading = useSelector(forgetPasswordLoadingSelector);
  const isError = useSelector(forgetPasswordErrorSelector);

  return <div className={isMobileView ? classes.mobileContainer : classes.container}>
    {/* {token && <Navigate to="/perfomance" replace />} */}
    {!isLoading && isError && <Toast className={classes.toast} errorMessage={isError} type="error" />}
    {/*<Logo className={classes.logo} mobile={false} />*/}
    <ForgotPasswordForm isLoading={isLoading} />
  </div>;
}
